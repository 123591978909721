import React, { Component } from 'react';
import 'typeface-roboto';

// partial react components
import Header from '../components/navigation/Header';

class ThaiwaterApp extends Component {
  render() {
    return (
      <>
        <Header />
      </>
    );
  }
}

export default ThaiwaterApp;
