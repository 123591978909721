import React from 'react';
import ReactDOM from 'react-dom';
import Root from './containers/Root';
import 'bootstrap/dist/css/bootstrap.min.css';

if (document.getElementById('app')) {
  ReactDOM.render(
    <Root />,
    document.getElementById('app'),
  );
}
