import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
// import PropTypes from 'prop-types';

// ui
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Tooltip from '@material-ui/core/Tooltip';

// menu
import LeftMenu from './LeftMenu';

// routes
import routes from '../../routes/route';

// constant
const initPath = process.env.MIX_APP_PATH;
const drawerWidth = 200;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#FFF',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#A7A7A7',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  horLine1: {
    height: '8px',
    backgroundColor: '#74388E',
  },
  horLine2: {
    height: '5px',
    backgroundColor: '#F6AC3F',
  },
}));

function Header() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <Router>
      <div className={ classes.root }>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={ clsx(classes.appBar, {
            [classes.appBarShift]: open,
          }) }
        >
          <Toolbar>
            <Tooltip title="ขยายเมนู" aria-label="open-drawer">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={ handleDrawerOpen }
                edge="start"
                className={ clsx(classes.menuButton, {
                  [classes.hide]: open,
                }) }
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" noWrap className={ classes.title }>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography variant="h5">
                    <img src={ `${initPath}images/logo.png` } alt="Logo" height={ 55 } />
                    <span style={ { color: '#000', fontWeight: 'bold' } }> พัชรสุธาคชานุรักษ์ - </span>
                    <span style={ { color: '#74388E' } }>ระบบข้อมูลพื้นฐาน</span>
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Toolbar>
          <div className={ classes.horLine1 } />
          <div className={ classes.horLine2 } />
        </AppBar>
        <Drawer
          className={ classes.drawer }
          variant="persistent"
          anchor="left"
          open={ open }
          classes={ {
            paper: classes.drawerPaper,
          } }
        >
          <div className={ classes.drawerHeader }>
            <IconButton onClick={ handleDrawerClose }>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <LeftMenu />
        </Drawer>

        <main className={ classes.content }>
          <Switch>
            {routes.map(route => (
              <Route
                key={ route.path }
                path={ route.path }
                exact={ route.exact }
                component={ route.component }
              />
            ))}
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default Header;
