import React,
{
  Component,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// ui
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowUp';
// css
import 'leaflet/dist/leaflet.css';

// components
import MainMap from './MainMap';

// constant
const styles = theme => ({
  body: { overflow: 'hidden' },
  button: {
    margin: theme.spacing(),
    backgroundColor: '#FFF',
    '&:hover': {
      background: '#f7f7f7',
    },
  },
  rightIcon: { marginLeft: theme.spacing(1) },
  Main: {
    width: '100%',
    top: '3em',
    position: 'relative',
  },
  root: {
    height: 180,
  },
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1),
  },
  area: {
    width: '100%',
    height: '23em',
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueYear: '2015',
      isLoaded: false,
      dataElephantOnYear: [],
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    const { valueYear } = this.state;
    this.connectAPI(valueYear);
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
    document.body.style.overflow = 'auto';
  }

  connectAPI(year) {
    // Elephants
    fetch(`api/elephants?year=${year}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Cannot load data ...');
      })
      .then(result => {
        this.setState({
          dataElephantOnYear: result,
          isLoaded: true,
        });
      })
      .catch(error => this.setState({ error }));
  }

  handleChange(event) {
    this.setState({ valueYear: event.target.value });
    this.connectAPI(event.target.value);
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;
    const { valueYear } = this.state;
    const { isLoaded, dataElephantOnYear } = this.state;
    let errorMsg = '';
    if (error) {
      errorMsg = (
        <Paper className={ classes.root } elevation={ 1 }>
          <Typography component="p">
            {error}
          </Typography>
        </Paper>
      );
    }
    return (
      <div>
        {errorMsg}
        {/* -------------------- container-filter-year PC -------------------- */}
        <div className="container-filter-year">
          <FormControl component="fieldset">
            <Typography component="p" variant="h6">
              ตำแหน่งที่พบช้างรายปี
            </Typography>
            <RadioGroup aria-label="position" name="position" value={ valueYear } onChange={ this.handleChange } row>
              <FormControlLabel
                value="2009"
                control={ <Radio color="primary" /> }
                label="2009"
                labelPlacement="end"
              />
              <FormControlLabel
                value="2010"
                control={ <Radio color="primary" /> }
                label="2010"
                labelPlacement="end"
              />
              <FormControlLabel
                value="2011"
                control={ <Radio color="primary" /> }
                label="2011"
                labelPlacement="end"
              />
              <FormControlLabel
                value="2012"
                control={ <Radio color="primary" /> }
                label="2012"
                labelPlacement="end"
              />
              <FormControlLabel
                value="2013"
                control={ <Radio color="primary" /> }
                label="2013"
                labelPlacement="end"
              />
              <FormControlLabel
                value="2014"
                control={ <Radio color="primary" /> }
                label="2014"
                labelPlacement="end"
              />
              <FormControlLabel
                value="2015"
                control={ <Radio color="primary" /> }
                label="2015"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {/* -------------------- container-filter-year PC-------------------- */}

        {/* -------------------- container-filter-year Mobile -------------------- */}
        <div className="container-filter-year-mobile">
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={ classes.heading }>
                ตำแหน่งที่พบช้างรายปี
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography component="span">
                <FormControl component="fieldset">
                  <RadioGroup aria-label="position" name="position" value={ valueYear } onChange={ this.handleChange } row>
                    <FormControlLabel
                      value="2009"
                      control={ <Radio color="primary" /> }
                      label="2009"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2010"
                      control={ <Radio color="primary" /> }
                      label="2010"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2011"
                      control={ <Radio color="primary" /> }
                      label="2011"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2012"
                      control={ <Radio color="primary" /> }
                      label="2012"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2013"
                      control={ <Radio color="primary" /> }
                      label="2013"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2014"
                      control={ <Radio color="primary" /> }
                      label="2014"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2015"
                      control={ <Radio color="primary" /> }
                      label="2015"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        {/* -------------------- container-filter-year Mobile -------------------- */}
        {/* -------------------- container-table -------------------- */}
        {/* -------------------- container-map -------------------- */}
        {isLoaded ? <MainMap data={ dataElephantOnYear } year={ valueYear } /> : null }
        {/* -------------------- container-map -------------------- */}
      </div>
    );
  }
}

Main.propTypes = { classes: PropTypes.object.isRequired };
export default withStyles(styles)(Main);
