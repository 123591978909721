// react components
import Main from '../components/main/Main';
import Copyright from '../components/copyright/Copyright';

const initPath = process.env.MIX_APP_PATH;
const routes = [
  {
    path: initPath,
    exact: true,
    component: Main,
  },
  {
    path: `${initPath}copyright`,
    exact: true,
    component: Copyright,
  },
];

export default routes;
