import React, {
  createRef,
  Component,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import L from 'leaflet';
import {
  Map,
  TileLayer,
  LayersControl,
  WMSTileLayer,
  FeatureGroup,
  Marker,
  CircleMarker,
  Tooltip,
  Popup,
} from 'react-leaflet';
// import L from 'leaflet';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowUp';

const wmsPath = process.env.MIX_WMS_URL;
const { Overlay } = LayersControl;
const initPath = process.env.MIX_APP_PATH;
const iconCctv = new L.Icon({
  iconUrl: `${initPath}images/marker/cctv/camera.png`,
  iconSize: [20, 20],
});
const styles = theme => ({
  body: {
    overflow: 'hidden',
  },
  button: {
    margin: theme.spacing(),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  Main: {
    width: '100%',
    left: '0',
    right: '0',
    bottom: '0',
    top: '80px',
    position: 'absolute',
  },
  gridList: {
    width: 500,
    height: 220,
  },
});

class MainMap extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.refMap = createRef();
    this.state = {
      zoom: 9,
      minZoom: 6,
      maxZoom: 15,
      location: [13.15, 101.75],
      dataElephant: [],
      dataElephantMovement: [],
      dataPark: [],
      dataNatureInfra: [],
      dataCctv: [],
      dataVillage: [],
    };
    this.refElephantInsideAngLayer = createRef();
    this.refParkLayer = createRef();
    this.refNatureInfraLayer = createRef();
    this.refCctvLayer = createRef();

    this.myRenderer = L.canvas({ padding: 0.5 });
  }

  componentDidMount() {
    this._mounted = true;
    this.map = this.refMap.current.leafletElement;
    this.map.createPane('pane_santuary_park');
    this.map.getPane('pane_santuary_park').style.zIndex = 200;

    this.map.createPane('pane_prakat');
    this.map.getPane('pane_prakat').style.zIndex = 201;

    const { props } = this;
    this.setState({ dataElephant: props.data });
    const layout = document.getElementsByClassName('leaflet-control-layers-selector');
    const lable = document.getElementsByClassName('leaflet-control-layers-overlays');

    setTimeout(() => {
      // custom grouped layer
      lable[0].children[0].style.fontWeight = 'bold';
      lable[0].children[0].style.fontSize = '14px';
      lable[0].children[8].style.fontWeight = 'bold';
      lable[0].children[8].style.fontSize = '14px';
      lable[0].children[12].style.fontWeight = 'bold';
      lable[0].children[12].style.fontSize = '14px';
      lable[0].children[15].style.fontWeight = 'bold';
      lable[0].children[15].style.fontSize = '14px';
      layout[0].type = 'hidden';
      layout[8].type = 'hidden';
      layout[12].type = 'hidden';
      layout[15].type = 'hidden';
    }, 500);
    this.connectAPI();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      dataElephant: nextProps.data,
    };
  }

  // https://stackoverflow.com/questions/40901539/arbitrary-function-on-react-leaflet-marker-click
  // handleClick = event => {
  //   const { lat, lng } = event.latlng;
  //   console.log(`Clicked at ${lat}, ${lng}`)
  // }

  connectAPI() {
    // elephant movement
    fetch('api/elephant_movements').then(response => response.json()).then(result => {
      this.setState({ dataElephantMovement: result });
    }).catch(error => {
      alert('Data Connection Failed.', error); // eslint-disable-line no-alert
    });

    // park
    fetch('api/park').then(response => response.json()).then(result => {
      this.setState({ dataPark: result });
    }).catch(error => {
      alert('Data Connection Failed.', error); // eslint-disable-line no-alert
    });

    // nature_infra
    fetch('api/nature_infra').then(response => response.json()).then(result => {
      this.setState({ dataNatureInfra: result });
    }).catch(error => {
      alert('Data Connection Failed.', error); // eslint-disable-line no-alert
    });

    // cctv
    fetch('api/cctv').then(response => response.json()).then(result => {
      this.setState({ dataCctv: result });
    }).catch(error => {
      alert('Data Connection Failed.', error); // eslint-disable-line no-alert
    });

    // village
    fetch('api/villages').then(response => response.json()).then(result => {
      this.setState({ dataVillage: result });
    }).catch(error => {
      alert('Data Connection Failed.', error); // eslint-disable-line no-alert
    });
  }

  render() {
    const { classes } = this.props;
    const {
      location,
      zoom,
      maxZoom,
      minZoom,
      dataElephant,
      dataElephantMovement,
      dataPark,
      dataNatureInfra,
      dataCctv,
      dataVillage,
    } = this.state;

    function DotColor(value) {
      const style = {
        height: '20px',
        width: '20px',
        backgroundColor: '#209744',
        borderRadius: '50%',
        display: 'inline-block',
      };
      switch (value) {
        case 'elephantInside':
          style.backgroundColor = '#7D7E7E';
          return style;
        case 'park':
          style.backgroundColor = '#0083FF';
          return style;
        case 'natureInfra':
          style.backgroundColor = '#7B3D3D';
          return style;
        case 'cctv':
          style.backgroundColor = '#ffcc00';
          return style;
        case 'elephant_movement_cat_1':
          style.backgroundColor = '#0000ff';
          return style;
        case 'elephant_movement_cat_2':
          style.backgroundColor = '#ffaa00';
          return style;
        case 'elephant_movement_cat_3':
          style.backgroundColor = '#267300';
          return style;
        case 'elephant_movement_cat_4':
          style.backgroundColor = '#a900e6';
          return style;
        case 'elephant_movement_cat_5':
          style.backgroundColor = '#ffff00';
          return style;
        default:
          style.backgroundColor = 'white';
          return style;
      }
    }

    function RectColor(value) {
      const style = {
        height: '20px',
        width: '20px',
        backgroundColor: '#209744',
        display: 'inline-block',
      };
      switch (value) {
        case 'reservoir_prakat':
          style.backgroundColor = '#004DA7';
          return style;
        case 'santuary_park_santuary':
          style.backgroundColor = '#FFAA00';
          style.opacity = '0.5';
          return style;
        case 'santuary_park_forest_park':
          style.backgroundColor = '#38A800';
          style.opacity = '0.5';
          return style;
        case 'forest_conservation':
          style.backgroundColor = '#D3FFBE';
          style.opacity = '0.5';
          return style;
        case 'five_provinces':
          style.backgroundColor = '#deebf7';
          style.opacity = '0.3';
          style.border = '1px solid #757575';
          return style;
        case 'meadow':
          style.backgroundColor = '#b7484b';
          return style;
        case 'forest_park':
          style.backgroundColor = '#e77148';
          return style;
        // case 'landuse':
        //   style.backgroundColor = '#d95f0e';
        //   return style;
        case 'land_use_khunsong':
          style.backgroundColor = '#795548';
          return style;
        case 'affected_area_from_elephant_migration':
          style.backgroundColor = '#D7B78F';
          return style;
        case 'no_hunting_area_east':
          style.backgroundColor = '#85b66f';
          return style;
        case 'wildlife_sanctuary_east':
          style.backgroundColor = '#d5b43c';
          return style;
        case 'water_mgt_office':
          style.backgroundColor = '#FFF';
          style.border = '1px solid #de2d26';
          return style;
        // landuse
        case 'U':
          style.backgroundColor = '#73004C';
          return style;
        case 'A1':
          style.backgroundColor = '#FFFF73';
          return style;
        case 'A2':
          style.backgroundColor = '#FF5500';
          return style;
        case 'A3':
          style.backgroundColor = '#9BB29B';
          return style;
        case 'A4':
          style.backgroundColor = '#FFBEBE';
          return style;
        case 'A5':
          style.backgroundColor = '#F57A7A';
          return style;
        case 'A6':
          style.backgroundColor = '#BEFFE8';
          return style;
        case 'A8':
          style.backgroundColor = '#002673';
          return style;
        case 'A0':
          style.backgroundColor = '#98E600';
          return style;
        case 'F':
          style.backgroundColor = '#267300';
          return style;
        case 'W':
          style.backgroundColor = '#0070FF';
          return style;
        case 'M':
          style.backgroundColor = '#9C9C9C';
          return style;
        default:
          style.backgroundColor = 'white';
          return style;
      }
    }

    function LineColor(value) {
      const style = {
        height: '2px',
        width: '20px',
        backgroundColor: '#209744',
        display: 'inline-block',
      };
      switch (value) {
        case 'food_plant_banana':
          style.backgroundColor = '#16A406';
          return style;
        case 'food_plant_vetiver':
          style.backgroundColor = '#F902C3';
          return style;
        default:
          style.backgroundColor = 'white';
          return style;
      }
    }

    function Icon(value) {
      const style = {
        height: '20px',
        width: '22px',
        display: 'inline-block',
      };

      switch (value) {
        case 'village':
          style.backgroundImage = "url('images/marker/village/village.png')";
          return style;
        case 'cctv':
          style.backgroundImage = "url('images/marker/cctv/camera.png')";
          return style;
        default:
          return style;
      }
    }

    const legendMarkers = [
      { mean: 'ข้อมูลทางกายภาพ' },
      { icon: DotColor('park'), mean: 'ตำแหน่งหน่วยพิทักษ์อุทยาน' },
      { icon: DotColor('natureInfra'), mean: 'โครงสร้างแหล่งน้ำ-ฝาย-ทุ่งหญ้า-โป่งดิน' },
      { icon: Icon('village'), mean: 'ตำแหน่งหมู่บ้าน' },
      { icon: RectColor('reservoir_prakat'), mean: 'พื้นที่อ่างเก็บน้ำประแกต' },
      { icon: RectColor('santuary_park_santuary'), mean: 'ขอบเขตอุทยานและเขตรักษาพันธุ์ฯ > เขตรักษาพันธุ์สัตว์ป่า' },
      { icon: RectColor('santuary_park_forest_park'), mean: 'ขอบเขตอุทยานและเขตรักษาพันธุ์ฯ > อุทยานแห่งชาติ' },
      { icon: RectColor('five_provinces'), mean: 'ขอบเขต 5 จังหวัด' },
      { mean: 'ข้อมูลทางชีวภาพ' },
      { icon: LineColor('food_plant_banana'), mean: 'แนวปลูกพืชอาหารช้าง > แนวปลูกกล้วยป่า' },
      { icon: LineColor('food_plant_vetiver'), mean: 'แนวปลูกพืชอาหารช้าง > แนวปลูกหญ้าแฝก' },
      { icon: RectColor('meadow'), mean: 'พื้นที่ทุ่งหญ้า' },
      { icon: RectColor('forest_park'), mean: 'พื้นที่สวนป่า' },
      { mean: 'ข้อมูลทางเศรษฐกิจและสังคม' },
      // { icon: RectColor('land_use'), mean: 'ขอบเขตการใช้ประโยชน์ที่ดิน (ปี 2561)' },
      { icon: RectColor('land_use_khunsong'), mean: 'แปลงการใช้ประโยชน์ที่ดินป่าขุนซ่อง' },
      { mean: 'ข้อมูลเชิงบริหารจัดการ' },
      { icon: Icon('cctv'), mean: 'กล้องดักถ่ายภาพสัตว์' },
      { icon: DotColor('elephantInside'), mean: 'ตำแหน่งการกระจายตัวของช้างป่า' },
      { icon: RectColor('affected_area_from_elephant_migration'), mean: 'พื้นที่ได้รับผลกระทบจากการเคลื่อนย้ายของช้างป่า' },
      { icon: RectColor('no_hunting_area_east'), mean: 'เขตห้ามล่าสัตว์ป่าตะวันออก' },
      { icon: RectColor('wildlife_sanctuary_east'), mean: 'พื้นที่เขตรักษาพันธุ์สัตว์ป่าตะวันออก' },
      { icon: RectColor('water_mgt_office'), mean: 'ขอบเขตสำนักบริหารจัดการน้ำและอุทกวิทยา' },
    ];

    const legendLanduse = [
      { mean: 'การใช้ประโยชน์ที่ดิน' },
      { icon: RectColor('U'), mean: 'พื้นที่เมืองและชุมชน' },
      { icon: RectColor('A1'), mean: 'นาข้าว' },
      { icon: RectColor('A2'), mean: 'พื้ชไร่' },
      { icon: RectColor('A3'), mean: 'ไม้ยืนต้น' },
      { icon: RectColor('A4'), mean: 'ไม้ผล' },
      { icon: RectColor('A5'), mean: 'พื้นสวน' },
      { icon: RectColor('A6'), mean: 'ทุ่งหญ้าเลี้ยงสัตว์ ไร่หมุนเวียน' },
      { icon: RectColor('A8'), mean: 'เพาะเลี้ยงสัตว์น้ำ พืชน้ำ' },
      { icon: RectColor('A0'), mean: 'พื้นที่การเกษตรอื่นๆ' },
      { icon: RectColor('F'), mean: 'ป่าไม้' },
      { icon: RectColor('W'), mean: 'แหล่งน้ำ' },
      { icon: RectColor('M'), mean: 'เบ็ดเตล็ด' },
    ];

    const legendElephantMovement = [
      { mean: 'การเคลื่อนที่ของข้าง' },
      { icon: DotColor('elephant_movement_cat_1'), mean: 'ช้างประจำถิ่น อ.ท่าตะเกียบ' },
      { icon: DotColor('elephant_movement_cat_2'), mean: 'ช้างประจำถิ่น อ.สนามชัยเชต' },
      { icon: DotColor('elephant_movement_cat_3'), mean: 'พื้นที่ช้างป่าพักอาศัย อ.ท่าตะเกียบ' },
      { icon: DotColor('elephant_movement_cat_4'), mean: 'พื้นที่ช้างป่าพักอาศัย อ.สนามชัยเขต' },
      { icon: DotColor('elephant_movement_cat_5'), mean: 'พื้นที่ช้างป่าออกหากิน อ.ท่าตะเกียบ' },
    ];

    function getColor(stype) {
      switch (stype) {
        case 'ElephantInside':
          return '#7D7E7E';
        case 'natureInfra':
          return '#7B3D3D';
        case 'park':
          return '#0083FF';
        case 'cctv':
          return '#ffcc00';
        default:
          return 'white';
      }
    }

    function getVillageColor(type) {
      switch (type) {
        case 'หมู่บ้านประสบภัย':
          return '#F95508';
        case 'หมู่บ้านนำร่อง':
          return '#801599';
        case 'หมู่บ้านขยายผล':
          return '#4CFF00';
        default:
          return 'white';
      }
    }

    function getElephantMovementColor(type) {
      switch (type) {
        case 1:
          return '#0000ff';
        case 2:
          return '#ffaa00';
        case 3:
          return '#267300';
        case 4:
          return '#a900e6';
        case 5:
          return '#ffff00';
        default:
          return 'white';
      }
    }

    const prefercanvas = true;

    return (
      <>
        <Map
          id="map"
          center={ location }
          zoom={ zoom }
          ref={ this.refMap }
          minZoom={ minZoom }
          maxZoom={ maxZoom }
          className={ classes.Main }
          preferCanvas={ prefercanvas }
        >
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            attribution=""
          />

          <LayersControl position="topright">
            {/* ================ Title Group - ข้อมูลทางกายภาพ */}
            <Overlay key="GroupLayer_1" name="ข้อมูลทางกายภาพ">
              <WMSTileLayer />
            </Overlay>
            {/* ตำแหน่งหน่วยพิทักษ์อุทยาน */}
            <Overlay key="point_park_guard_unit" name="ตำแหน่งหน่วยพิทักษ์อุทยาน">
              <FeatureGroup ref={ this.refParkLayer }>
                {dataPark.map(
                  (data, index) => (
                    <CircleMarker
                      key={ index.toString() }
                      center={ [Number(data.y), Number(data.x)] }
                      radius={ 7 }
                      fillOpacity={ 0.5 }
                      weight={ 1 }
                      stroke={ false }
                      fillColor={ getColor('park') }
                    >
                      <Tooltip direction="top" offset={ [-8, -2] } opacity={ 1 }>
                        <Typography variant="subtitle2">
                          {data.name_t}
                        </Typography>
                      </Tooltip>
                    </CircleMarker>
                  ),
                )}
              </FeatureGroup>
            </Overlay>
            {/* โครงสร้างแหล่งน้ำ-ฝาย-ทุ่งหญ้า-โป่งดิน */}
            <Overlay key="point_nature_infra" name="โครงสร้างแหล่งน้ำ-ฝาย-ทุ่งหญ้า-โป่งดิน">
              <FeatureGroup ref={ this.refNatureInfraLayer }>
                {dataNatureInfra.map(
                  (data, index) => (
                    <CircleMarker
                      key={ index.toString() }
                      center={ [Number(data.y), Number(data.x)] }
                      radius={ 7 }
                      fillOpacity={ 0.5 }
                      weight={ 1 }
                      stroke={ false }
                      fillColor={ getColor('natureInfra') }
                    >
                      <Tooltip direction="top" offset={ [-8, -2] } opacity={ 1 }>
                        <Typography variant="subtitle2">
                          {data.type}
                        </Typography>
                        <Typography variant="body1">
                          {data.record}
                        </Typography>
                      </Tooltip>
                    </CircleMarker>
                  ),
                )}
              </FeatureGroup>
            </Overlay>
            {/* ตำแหน่งหมู่่บ้าน */}
            <Overlay key="point_village" name="ตำแหน่งหมู่บ้าน" checked>
              <FeatureGroup>
                {dataVillage.map(
                  (data, index) => (
                    <Marker
                      key={ index.toString() }
                      position={ [Number(data.lat), Number(data.long)] }
                      icon={ L.divIcon({
                        className: 'ship-div-icon',
                        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15" fill="${getVillageColor(data.group)}" fill-opacity="0.8"><path stroke="#424242" d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 10 21 L 10 15 L 14 15 L 14 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z"/></svg>`,
                      }) }
                    >
                      <Tooltip direction="top" offset={ [-8, -2] } opacity={ 1 }>
                        <Typography variant="subtitle2">
                          {data.group}
                        </Typography>
                        {`หมู่ที่ ${data.moo_no} ${data.moo}`}
                        <br />
                        {`ค.${data.tambon} อ.${data.amphoe} จ.${data.province}`}
                      </Tooltip>
                    </Marker>
                  ),
                )}
              </FeatureGroup>
            </Overlay>
            {/* v2 - พื้นที่อ่างเก็บน้ำประแกต */}
            <Overlay key="reservoir_prakat" name="พื้นที่อ่างเก็บน้ำประแกต" checked>
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_reservoir_prakat"
                srs="EPSG:4326"
                format="image/png"
                pane="pane_prakat"
              />
            </Overlay>
            {/* v2 - ขอบเขตพื้นที่เขาชะเมา-อ่างฤาไน */}
            <Overlay key="santuary_park" name="ขอบเขตอุทยานและเขตรักษาพันธุ์ฯ" checked>
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_santuary_park"
                srs="EPSG:4326"
                format="image/png"
                tiled
                pane="pane_santuary_park"
              />
            </Overlay>
            {/* v2 - พื้นที่เขตป่าสงวนแห่งชาติ */}
            <Overlay key="forest_conservation" name="พื้นที่เขตป่าสงวนแห่งชาติ" checked>
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_forest_conservation"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* v2 - ขอบเขต 5 จังหวัด */}
            <Overlay key="five_provinces" name="ขอบเขต 5 จังหวัด" checked>
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_five_provinces"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* ================ Title Group - ข้อมูลทางชีวภาพ */}
            <Overlay key="GroupLayer_2" name="ข้อมูลทางชีวภาพ">
              <WMSTileLayer />
            </Overlay>
            {/* v2 - แนวปลูกพืชอาหารช้าง */}
            <Overlay key="food_plant" name="แนวปลูกพืชอาหารช้าง">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:line_elephant_food_plant"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* v2 - พื้นที่ทุ่งหญ้า */}
            <Overlay key="meadow" name="พื้นที่ทุ่งหญ้า">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_meadow"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* v2 - พื้นที่สวนป่า */}
            <Overlay key="forest_park" name="พื้นที่สวนป่า">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_forest_park"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* ================ Title Group - ข้อมูลเศรษฐกิจสังคม */}
            <Overlay key="GroupLayer_3" name="ข้อมูลเศรษฐกิจสังคม">
              <WMSTileLayer />
            </Overlay>
            {/* v2 - ขอบเขตการใช้ประโยชน์ที่ดิน */}
            <Overlay key="land_use" name="ขอบเขตการใช้ประโยชน์ที่ดิน">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_landuse_2561"
                srs="EPSG:4326"
                format="image/png"
                tiled
              />
            </Overlay>
            {/* v2 - แปลงการใช้ประโยชน์ที่ดินป่าขุนซ่อง */}
            <Overlay key="land_use_khunsong" name="แปลงการใช้ประโยชน์ที่ดินป่าขุนซ่อง">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_land_use_khunsong"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* ================ Title Group - ข้อมูลเชิงบริหารจัดการ */}
            <Overlay key="GroupLayer_4" name="ข้อมูลเชิงบริหารจัดการ">
              <WMSTileLayer />
            </Overlay>
            {/* v2 - กล้องดักถ่ายภาพสัตว์ */}
            <Overlay key="point_cctv" name="กล้องดักถ่ายภาพสัตว์">
              <FeatureGroup ref={ this.refCctvLayer }>
                {dataCctv.map(
                  (data, index) => (
                    <Marker
                      key={ index.toString() }
                      position={ [Number(data.y), Number(data.x)] }
                      icon={ iconCctv }
                      // onClick={ this.handleClick }
                    >
                      <Tooltip direction="top" offset={ [-8, -2] } opacity={ 1 }>
                        <Typography variant="subtitle2">
                          {`กล้องดักถ่ายภาพสัตว์ ${data.station}`}
                        </Typography>
                      </Tooltip>
                      <Popup maxWidth={ 550 }>
                        <Typography variant="subtitle2">
                          {`กล้องดักถ่ายภาพสัตว์ ${data.station}`}
                        </Typography>
                        <GridList cellHeight={ 80 } className={ classes.gridList } cols={ 3 }>
                          <GridListTile key={ 1 }>
                            <img src={ `${initPath}images/sample/01.jpg` } alt="elephant" />
                          </GridListTile>
                          <GridListTile key={ 2 }>
                            <img src={ `${initPath}images/sample/02.jpg` } alt="elephant" />
                          </GridListTile>
                          <GridListTile key={ 3 }>
                            <img src={ `${initPath}images/sample/03.jpg` } alt="elephant" />
                          </GridListTile>
                          <GridListTile key={ 4 }>
                            <img src={ `${initPath}images/sample/04.jpg` } alt="elephant" />
                          </GridListTile>
                        </GridList>
                      </Popup>
                    </Marker>
                  ),
                )}
              </FeatureGroup>
            </Overlay>
            {/* v2 - ตำแหน่งการกระจายตัวของช้างป่า */}
            <Overlay key="point_elephant_distribution" name="ตำแหน่งการกระจายตัวของช้างป่า" checked>
              <FeatureGroup ref={ this.refElephantInsideAngLayer }>
                {dataElephant.map(
                  (data, index) => (
                    <CircleMarker
                      key={ index.toString() }
                      center={ [Number(data.y), Number(data.x)] }
                      radius={ 4 }
                      fillOpacity={ 0.5 }
                      weight={ 1 }
                      stroke
                      color="#424242"
                      fillColor={ getColor('ElephantInside') }
                      // renderer={this.myRenderer}
                    >
                      <Tooltip direction="top" offset={ [-8, -2] } opacity={ 1 }>
                        <div className={ classes.title }>
                          <Typography variant="h6" id="tableTitle">
                            {`${data.place} (${data.day})`}
                          </Typography>
                        </div>

                        <Table className={ classes.table } size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell align="right">
                                กิจกรรม :
                              </TableCell>
                              <TableCell>
                                {data.activity ? ` ${data.activity}` : ' - '}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="right">
                                พื้นที่ :
                              </TableCell>
                              <TableCell>
                                {data.moo ? `${data.moo}` : ''}
                                {data.tambon ? ` ต.${data.tambon}` : ''}
                                <br />
                                {data.amphoe ? ` อ.${data.amphoe}` : ''}
                                {data.province ? ` จ.${data.province}` : ''}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="right">
                                จำนวนช้าง (เชือก) :
                              </TableCell>
                              <TableCell>
                                {data.total_anim ? data.total_anim : ' - '}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Tooltip>
                    </CircleMarker>
                  ),
                )}
              </FeatureGroup>
            </Overlay>
            {/* การเคลื่อนที่ของช้าง */}
            <Overlay key="point_elephant_movement" name="ตำแหน่งการเคลื่อนที่ของช้างป่า" checked>
              <FeatureGroup ref={ this.refElephantInsideAngLayer }>
                {dataElephantMovement.map(
                  (data, index) => (
                    <CircleMarker
                      key={ index.toString() }
                      center={ [Number(data.y), Number(data.x)] }
                      radius={ 6 }
                      fillOpacity={ 0.5 }
                      weight={ 1 }
                      stroke
                      color="#424242"
                      fillColor={ getElephantMovementColor(data.type) }
                      // renderer={this.myRenderer}
                    >
                      <Tooltip direction="top" offset={ [-8, -2] } opacity={ 1 }>
                        <Typography variant="subtitle2">
                          {data.type1}
                        </Typography>
                        {`ค.${data.tambon} อ.${data.amphoe} จ.${data.province}`}
                      </Tooltip>
                    </CircleMarker>
                  ),
                )}
              </FeatureGroup>
            </Overlay>
            {/* v2 - พื้นที่ได้รับผลกระทบจากการเคลื่อนย้ายของช้างป่า */}
            <Overlay key="affected_area_from_elephant_migration" name="พื้นที่ได้รับผลกระทบจากการเคลื่อนย้ายของช้างป่า">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_affected_area_from_elephant_migration"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* v2 - เขตห้ามล่าสัตว์ป่าตะวันออก */}
            <Overlay key="no_hunting_area_east" name="เขตห้ามล่าสัตว์ป่าตะวันออก">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_no_hunting_area_east"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* v2 - พื้นที่เขตรักษาพันธุ์สัตว์ป่าตะวันออก */}
            <Overlay key="wildlife_sanctuary_east" name="พื้นที่เขตรักษาพันธุ์สัตว์ป่าตะวันออก">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_wildlife sanctuary_east"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
            {/* v2 - ขอบเขตสำนักบริหารจัดการน้ำและอุทกวิทยา */}
            <Overlay key="water_mgt_office" name="ขอบเขตสำนักบริหารจัดการน้ำและอุทกวิทยา">
              <WMSTileLayer
                url={ wmsPath }
                version="1.1.0"
                opacity={ 1 }
                transparent
                layers="elephant:boundary_water_mgt_office"
                srs="EPSG:4326"
                format="image/png"
              />
            </Overlay>
          </LayersControl>
          <div className="styleLegend">
            <div className="expansion">
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={ <ExpandMoreIcon /> }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={ classes.heading }>คำอธิบายสัญลักษณ์</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={ { height: '500px', overflow: 'scroll' } }>
                  <Typography component="span">
                    <Paper className={ classes.root }>
                      <Table className={ classes.table }>
                        <TableBody>
                          {legendMarkers.map(
                            (row, index) => (
                              <TableRow key={ String(index) }>
                                { row.icon ? (
                                  <>
                                    <TableCell align="center">
                                      <div>
                                        <span style={ row.icon } key={ row.icon } />
                                      </div>
                                    </TableCell>
                                    <TableCell align="left">
                                      <span>{ row.mean }</span>
                                    </TableCell>
                                  </>
                                ) : (
                                  <TableCell colSpan={ 2 } align="left" style={ { backgroundColor: '#EEE' } }>
                                    <strong>{ row.mean }</strong>
                                  </TableCell>
                                )}
                              </TableRow>
                            ),
                          )}

                          {legendLanduse.map(
                            (row, index) => (
                              <TableRow key={ String(index) }>
                                { row.icon ? (
                                  <>
                                    <TableCell align="center">
                                      <div>
                                        <span style={ row.icon } key={ row.icon } />
                                      </div>
                                    </TableCell>
                                    <TableCell align="left">
                                      <span>{ row.mean }</span>
                                    </TableCell>
                                  </>
                                ) : (
                                  <TableCell colSpan={ 2 } align="left" style={ { backgroundColor: '#EEE' } }>
                                    <strong>{ row.mean }</strong>
                                  </TableCell>
                                )}
                              </TableRow>
                            ),
                          )}

                          {legendElephantMovement.map(
                            (row, index) => (
                              <TableRow key={ String(index) }>
                                { row.icon ? (
                                  <>
                                    <TableCell align="center">
                                      <div>
                                        <span style={ row.icon } key={ row.icon } />
                                      </div>
                                    </TableCell>
                                    <TableCell align="left">
                                      <span>{ row.mean }</span>
                                    </TableCell>
                                  </>
                                ) : (
                                  <TableCell colSpan={ 2 } align="left" style={ { backgroundColor: '#EEE' } }>
                                    <strong>{ row.mean }</strong>
                                  </TableCell>
                                )}
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </div>
        </Map>
      </>
    );
  }
}

MainMap.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default withStyles(styles)(MainMap);
