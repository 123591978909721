import React from 'react';
import {
  Link,
} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

const initPath = process.env.MIX_APP_PATH;
const useStyles = makeStyles({
  listItemText: {
    fontFamily: 'Kanit',
    color: '#000',
  },
});

function LeftMenu() {
  const classes = useStyles();

  return (
    <List>
      <a href="http://www.kajanurak.org" rel="noopener noreferrer">
        <ListItem button key="main">
          <ListItemIcon>
            <Tooltip title="ข้อมูลโครงการ" aria-label="home">
              <img src={ `${initPath}images/menu/home.png` } alt="home" width="30" height="30" style={ { marginLeft: 3 } } />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="ข้อมูลโครงการ" classes={ { primary: classes.listItemText } } /> 
        </ListItem>
      </a>
      <Link to={ `${initPath}` }>
        <ListItem button key="main">
          <ListItemIcon>
            <Tooltip title="ข้อมูลพื้นฐาน" aria-label="map">
              <img src={ `${initPath}images/menu/map.png` } alt="map" width="30" height="30" style={ { marginLeft: 3 } } />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="ข้อมูลพื้นฐาน" classes={ { primary: classes.listItemText } } /> 
        </ListItem>
      </Link>
      <Link to={ `${initPath}copyright` }>
        <ListItem button key="copyright">
          <ListItemIcon>
            <Tooltip title="แหล่งข้อมูล" aria-label="data_source">
              <img src={ `${initPath}images/menu/copyright.png` } alt="data_source" width="30" height="30" style={ { marginLeft: 3 } } />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="แหล่งข้อมูล" classes={ { primary: classes.listItemText } } />
        </ListItem>
      </Link>
    </List>
  );
}

export default LeftMenu;
