import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import ElephantTrackingApp from './ElephantTrackingApp';

// custom theme
const theme = createMuiTheme({
  indicator: {
    backgroundColor: '#ff0000',
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 15,
    h5: {
      fontFamily: 'Kanit',
      fontSize: 22,
      fontWeight: 'strong',
    },
    h6: {
      fontFamily: 'Kanit',
      fontSize: 18,
    },
    subtitle1: {
      fontFamily: 'Kanit',
    },
    subtitle2: {
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '5px 10px 5px 10px',
      },
    },
    MuiDialogContent: {
      root: {
        margin: 'auto',
      },
    },
  },
});

class RootContainer extends Component {
  render() {
    return (
      <MuiThemeProvider theme={ theme }>
        <Helmet>
          <link rel="preconnect" href="https://server.arcgisonline.com" />
          <link rel="preconnect" href="http://igis.thaiwater.net" />
        </Helmet>
        <ElephantTrackingApp />
      </MuiThemeProvider>
    );
  }
}

export default RootContainer;
