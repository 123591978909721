import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const initPath = process.env.MIX_APP_PATH;
const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(3, 2),
  },
  copyright: {
    marginTop: '90px',
    padding: theme.spacing(3, 2),
  },
}));

export default function Copyright() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid container className={ classes.root } spacing={ 3 }>
        <Grid item xs={ 12 }>
          <Paper className={ classes.root }>
            <div className={ classes.copyright }>
              <Typography variant="h5" data-testid="title-typography">
                แหล่งที่มาข้อมูล
              </Typography>
              <Table className={ classes.table } aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>หน่วยงาน</TableCell>
                    <TableCell>ข้อมูล</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={ 1 }>
                    <TableCell component="th" scope="row" align="center" style={ { verticalAlign: 'top' } }>
                      <img src={ `${initPath}images/logo-dnp.jpg` } alt="dnp" width="100" />
                      <br />
                      <Typography variant="h6">
                        กรมอุทยานสัตว์ป่าและพันธุ์พืช
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ul>
                        <li>ตำแหน่งหน่วยพิทักษ์อุทยาน</li>
                        <li>โครงสร้างแหล่งน้ำ-ฝาย-ทุ่งหญ้า-โป่งดิน</li>
                        <li>พื้นที่อ่างเก็บน้ำประแกต</li>
                        <li>ขอบเขตอุทยานและเขตรักษาพันธุ์ฯ</li>
                        <li>แนวปลูกพืชอาหารช้าง</li>
                        <li>พื้นที่ทุ่งหญ้า</li>
                        <li>พื้นที่สวนป่า</li>
                        <li>กล้องดักถ่ายภาพสัตว์</li>
                        <li>ตำแหน่งการกระจายตัวของช้างป่า</li>
                        <li>พื้นที่ได้รับผลกระทบจากการเคลื่อนย้ายของช้างป่า</li>
                        <li>เขตห้ามล่าสัตว์ป่าตะวันออก</li>
                        <li>พื้นที่เขตรักษาพันธุ์สัตว์ป่าตะวันออก</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                  <TableRow key={ 2 }>
                    <TableCell component="th" scope="row" align="center" style={ { verticalAlign: 'top' } }>
                      <img src={ `${initPath}images/logo_forest.png` } alt="forest" width="100" />
                      <br />
                      <Typography variant="h6">
                        กรมป่าไม้
                      </Typography>
                    </TableCell>
                    <TableCell style={ { verticalAlign: 'top' } }>
                      <ul>
                        <li>พื้นที่เขตป่าสงวนแห่งชาติ</li>
                        <li>ขอบเขตการใช้ประโยชน์ที่ดิน</li>
                        <li>แปลงการใช้ประโยชน์ที่ดินป่าขุนซ่อง</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                  <TableRow key={ 3 }>
                    <TableCell component="th" scope="row" align="center" style={ { verticalAlign: 'top' } }>
                      <img src={ `${initPath}images/logo_cdd.png` } alt="forest" width="100" />
                      <br />
                      <Typography variant="h6">
                        กรมการพัฒนาชุมชน
                      </Typography>
                    </TableCell>
                    <TableCell style={ { verticalAlign: 'top' } }>
                      <ul>
                        <li>หมู่บ้าน (ข้อมูลอยู่ระหว่างการปรับปรุง)</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
